@import "fonts.css";

html {
  font-family: "DomaineDisplay", "Lucida Sans", sans-serif;
  font-size: 18px;
}

input,
textarea,
keygen,
select,
button {
  font-family: inherit;
  font-weight: inherit;
}

body {
  background-color: #eeeeee;
  margin: 0;
}
